import {CorsaInterface} from '../interfaces/corsa';

export class Corsa implements CorsaInterface {
  id: number;
  ora: string;
  part: string;
  dest: string;
  comp: string;
  mez: string;
  desc: string;
  sosp: string;
  attiva: string;
  creation_date: string;
  last_update: string;

  constructor() {
    this.id = 0;
    this.ora= '';
    this.part= '';
    this.dest= '';
    this.comp= '';
    this.mez= '';
    this.desc= '';
    this.sosp= '';
    this.attiva= '';
    this.creation_date= '';
    this.last_update='';
  }
}
