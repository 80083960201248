import {EventEmitter, Injectable, Output} from '@angular/core';
import {User} from "../classes/User";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import { environment } from 'src/environments/environment';

interface Jwt {
  access_token: string,
  token_type: string
  expires_in : number,
  nome: string,
  email: string
}
@Injectable()
export class AuthService {
  private isUserLogged = false;
  @Output() usersignedin = new EventEmitter<User>()
  @Output() usersignedup = new EventEmitter<User>()
  @Output() userlogout = new EventEmitter()

  private APIAUTHURL = environment.url_api;
  constructor(private http: HttpClient, private router: Router) {
  }

  isUserLoggedIn() {

    this.isUserLogged = !!localStorage.getItem('token');
    
    if(localStorage.getItem('token') == 'undefined'){
      this.isUserLogged = false;
    }
    return this.isUserLogged;
  }

  signIn(email: string, password: string) {

   return  this.http.post(this.APIAUTHURL + 'login.php',
      {
        username: email,
        password: password
      }
    ).pipe(
      tap(
        (payload: Jwt) => {
          console.log('payload',payload)
          localStorage.setItem('token', payload.access_token);
          localStorage.setItem('user' , JSON.stringify(payload));
          let user = new User();
          user.nome = payload.nome;
          user.email = payload.email;
          this.usersignedin.emit(user);
          return true;

        }
      )
    );


  }

  signUp(username: string, email: string, password: string) {


    const user = new User();
    user.nome = username;
    user.email = email;

    return this.http.post(this.APIAUTHURL + 'register.php',
      {
        email: email,
        password: password,
        name : username
      }
    ).pipe(tap(
      (payload: Jwt) => {
        localStorage.setItem('token', payload.access_token);
        console.log(payload);
        localStorage.setItem('user' , JSON.stringify(payload));

        this.usersignedup.emit(user);
      },
      (httpResp: HttpErrorResponse) => {

        alert(httpResp.message);
      }
    ));
  }
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.userlogout.emit();
    this.isUserLogged = false;
  }
  getUser(): User {
    const data = JSON.parse(localStorage.getItem('user'));
    let user = new User();
    if(data){
      user.nome = data['nome'];
      user.email = data['email'];
    }
    return user;
  }
  getToken() {
    
    if( this.isUserLoggedIn()){
      
      return localStorage.getItem('token');
    } else {
      
      this.router.navigate(['login']);
    }
    
  }
}
