<div class="uk-grid uk-padding-small" uk-grid>
    <div class="uk-width-1-3@m"></div>
    <div class="uk-width-1-1 uk-width-1-3@m">
        <ul class="uk-subnav" uk-margin>
            <li><a href="#" routerLink="/corse"><span uk-icon="arrow-left"></span> Torna alle corse</a></li>
        </ul>
        <form #f="ngForm" (submit)="createCorsa()" class="uk-margin-small-large uk-margin-small-top uk-grid-small" uk-grid>
            <input type="hidden" name="id">

            <div class="uk-margin-small uk-width-1-1">
                <label class="uk-form-label" for="ora">Orario</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-small@m" id="ora" type="text" placeholder="12:10" value="" name="ora" [(ngModel)]="Corsa.ora">
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="partenza">Partenza</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="partenza" name="part" [(ngModel)]="Corsa.part">
                        <option value="Amalfi">Amalfi</option>
                        <option value="Capri">Capri</option>
                        <option value="Casamicciola">Casamicciola</option>
                        <option value="Castellammare">Castellammare</option>
                        <option value="Forio">Forio</option>
                        <option value="Ischia">Ischia</option>
                        <option value="Napoli">Napoli</option>
                        <option value="Napoli (Mergellina)">Napoli (Mergellina)</option>
                        <option value="Napoli (Molo Beverello)">Napoli (Molo Beverello)</option>
                        <option value="Napoli (Porta di Massa)">Napoli (Porta di Massa)</option>
                        <option value="Positano">Positano</option>
                        <option value="Pozzuoli">Pozzuoli</option>
                        <option value="Procida">Procida</option>
                        <option value="Salerno">Salerno</option>
                        <option value="Sorrento">Sorrento</option>
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="destinazione">Destinazione</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="destinazione" name="dest" [(ngModel)]="Corsa.dest">
                        <option value="Amalfi">Amalfi</option>
                        <option value="Capri">Capri</option>
                        <option value="Casamicciola">Casamicciola</option>
                        <option value="Castellammare">Castellammare</option>
                        <option value="Forio">Forio</option>
                        <option value="Ischia">Ischia</option>
                        <option value="Napoli">Napoli</option>
                        <option value="Napoli (Mergellina)">Napoli (Mergellina)</option>
                        <option value="Napoli (Molo Beverello)">Napoli (Molo Beverello)</option>
                        <option value="Napoli (Porta di Massa)">Napoli (Porta di Massa)</option>
                        <option value="Positano">Positano</option>
                        <option value="Pozzuoli">Pozzuoli</option>
                        <option value="Procida">Procida</option>
                        <option value="Salerno">Salerno</option>
                        <option value="Sorrento">Sorrento</option>
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="compagnia">Compagnia</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="compagnia" name="comp" [(ngModel)]="Corsa.comp">
                        <option *ngFor="let compagnia of compagnieFormArray.controls; let i = index" value="{{compagnieData[i].value}}">{{compagnieData[i].name}}</option>
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="mezzo">Mezzo</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="mezzo" name="mez" [(ngModel)]="Corsa.mez">
                        <option value="Aliscafo">Aliscafo</option>
                        <option value="Traghetto">Traghetto</option>
                        <option value="Motonave">Motonave</option>
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-1">
                <label class="uk-form-label" for="descrizione">Descrizione</label>
                <div class="uk-form-controls">
                    <input class="uk-input" id="descrizione" type="text" placeholder="Descrizione" value="" name="desc" [(ngModel)]="Corsa.desc">
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <div class="uk-form-label">Attiva</div>
                <div class="uk-form-controls uk-form-controls-text uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-radio" type="radio" name="attiva" value="1" name="attiva" [(ngModel)]="Corsa.attiva" checked> Si</label><br>
                    <label><input class="uk-radio" type="radio" name="attiva" value="0" name="attiva" [(ngModel)]="Corsa.attiva"> No</label>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <div class="uk-form-label">Sospesa</div>
                <div class="uk-form-controls uk-form-controls-text uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-radio" type="radio" name="sospesa" value="1" name="sosp" [(ngModel)]="Corsa.sosp"> Si</label><br>
                    <label><input class="uk-radio" type="radio" name="sospesa" value="0" name="sosp" [(ngModel)]="Corsa.sosp" checked> No</label>
                </div>
            </div>

            <div class="uk-margin-large-bottom uk-width-1-1 uk-text-center">
                <button class="uk-button uk-button-primary" type="submit">Crea</button>
            </div>

        </form>
        
    </div>
</div>