import {Component} from '@angular/core';
import {User} from './classes/User';
import {Corsa} from './classes/Corsa';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Admin Panel';
  showForm = false;
  userSelected = new User();
  corsaSelected = new Corsa();

  updateUser(user: User) {
    this.showForm = true;
    this.userSelected = user;
  }
  newUser() {
    this.userSelected = new User();
    this.showForm = true;
  }

  updateCorsa(corsa: Corsa) {
    console.log('updateCorsa')
    this.corsaSelected = corsa;
  }
}
