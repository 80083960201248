import {UserInterface} from '../interfaces/user';

export class User implements UserInterface {
  id: number;
  nome: string;
  email: string;
  password: string;
  livello: number;

  constructor() {
    this.id = 0;
    this.nome = '';
    this.email = '';
  }
}
