import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Router } from "@angular/router";
import { CorsaService } from '../services/corsa.service';
import { AuthService } from "../services/auth.service";
import { Corsa } from '../classes/Corsa';
import { User } from '../classes/User';
declare var UIkit: any;

@Component({
  selector: 'app-corse',
  templateUrl: './corse.component.html',
  styleUrls: ['./corse.component.css']
})
export class CorseComponent implements OnInit {

  title = 'Corse';
  corse: Corsa[] = [];
  user: User;
  form: FormGroup;
  partenzeData = [
    {name: 'Amalfi', value: 'Amalfi'},
    {name: 'Capri', value: 'Capri'},
    {name: 'Casamicciola', value: 'Casamicciola'},
    {name: 'Castellammare', value: 'Castellammare'},
    {name: 'Forio', value: 'Forio'},
    {name: 'Ischia', value: 'Ischia'},
    {name: 'Napoli', value: 'Napoli'},
    {name: 'Napoli (Mergellina)', value: 'Napoli (Mergellina)'},
    {name: 'Napoli (Molo Beverello)', value: 'Napoli (Molo Beverello)'},
    {name: 'Napoli (Porta di Massa)', value: 'Napoli (Porta di Massa)'},
    {name: 'Positano', value: 'Positano'},
    {name: 'Pozzuoli', value: 'Pozzuoli'},
    {name: 'Procida', value: 'Procida'},
    {name: 'Salerno', value: 'Salerno'},
    {name: 'Sorrento', value: 'Sorrento'}
  ];
  destinazioniData = [
    {name: 'Amalfi', value: 'Amalfi'},
    {name: 'Capri', value: 'Capri'},
    {name: 'Casamicciola', value: 'Casamicciola'},
    {name: 'Castellammare', value: 'Castellammare'},
    {name: 'Forio', value: 'Forio'},
    {name: 'Ischia', value: 'Ischia'},
    {name: 'Napoli', value: 'Napoli'},
    {name: 'Napoli (Mergellina)', value: 'Napoli (Mergellina)'},
    {name: 'Napoli (Molo Beverello)', value: 'Napoli (Molo Beverello)'},
    {name: 'Napoli (Porta di Massa)', value: 'Napoli (Porta di Massa)'},
    {name: 'Positano', value: 'Positano'},
    {name: 'Pozzuoli', value: 'Pozzuoli'},
    {name: 'Procida', value: 'Procida'},
    {name: 'Salerno', value: 'Salerno'},
    {name: 'Sorrento', value: 'Sorrento'}
  ];
  compagnieData = [];
  attiveData = [
    {name: 'Attive', value: '1'},
    {name: 'Non attive', value: '0'},
  ];
  sospeseData = [
    {name: 'Sospese', value: '1'},
    {name: 'Non sospese', value: '0'},
  ];

  get partenzeFormArray() {
    return this.form.controls.partenze as FormArray;
  }
  get destinazioniFormArray() {
    return this.form.controls.destinazioni as FormArray;
  }
  get compagnieFormArray() {
    return this.form.controls.compagnie as FormArray;
  }
  get attiveFormArray() {
    return this.form.controls.attive as FormArray;
  }
  get sospeseFormArray() {
    return this.form.controls.sospese as FormArray;
  }

  constructor(private corsaService: CorsaService, private auth: AuthService, private router: Router, private formBuilder: FormBuilder){
    this.form = this.formBuilder.group({
      partenze: new FormArray([]),
      destinazioni: new FormArray([]),
      compagnie: new FormArray([]),
      attive: new FormArray([]),
      sospese: new FormArray([])
    });

    
  }

  private addCheckboxes() {
    this.partenzeData.forEach(() => this.partenzeFormArray.push(new FormControl(false)));
    this.destinazioniData.forEach(() => this.destinazioniFormArray.push(new FormControl(false)));
    this.compagnieData.forEach(() => this.compagnieFormArray.push(new FormControl(false)));
    this.attiveData.forEach(() => this.attiveFormArray.push(new FormControl(false)));
    this.sospeseData.forEach(() => this.sospeseFormArray.push(new FormControl(false)));
  }

  ngOnInit() {
    
    this.corsaService.getCorse({}).subscribe(
      res => this.corse = res['data'],
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );

    this.corsaService.getCompagnie().subscribe(
      res => {
        this.compagnieData = res['data'];
        this.addCheckboxes();
      },
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );

  }

  changeAttiva(corsa_id, attiva){
    console.log('changeAttiva')

    this.corsaService.changeAttiva(corsa_id, attiva).subscribe(
      response => {
          
        if (response['success']) {
          
          let message;
          if(attiva) message='Corsa attivata';
          else message='Corsa disattivata';

          UIkit.notification({
            message: message,
            status: 'primary',
            pos: 'top-right',
            timeout: 5000
          });
          console.log(response['data'])
        } else {
          alert(response['message']);
        }
      },
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );
  }

  changeSospesa(corsa_id, sospesa){
    console.log('changeSospesa')

    this.corsaService.changeSospesa(corsa_id, sospesa).subscribe(
      response => {
          
        if (response['success']) {
          let message;
          if(sospesa) message='Corsa sospesa';
          else message='Corsa regolare';

          UIkit.notification({
            message: message,
            status: 'primary',
            pos: 'top-right',
            timeout: 5000
          });
          console.log(response['data'])
        } else {
          alert(response['message']);
        }
      },
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );
  }

  filtraCorse(){
    const selectedPartenzeIds = this.form.value.partenze
      .map((checked, i) => checked ? this.partenzeData[i].value: null)
      .filter(v => v !== null);
    console.log('selectedPartenzeIds',selectedPartenzeIds);

    const selectedDestinazioniIds = this.form.value.destinazioni
      .map((checked, i) => checked ? this.destinazioniData[i].value: null)
      .filter(v => v !== null);
    console.log('selectedDestinazioniIds',selectedDestinazioniIds);

    const selectedCompagnieIds = this.form.value.compagnie
      .map((checked, i) => checked ? this.compagnieData[i].value: null)
      .filter(v => v !== null);
    console.log('selectedCompagnieIds',selectedCompagnieIds);

    const selectedAttiveIds = this.form.value.attive
      .map((checked, i) => checked ? this.attiveData[i].value: null)
      .filter(v => v !== null);
    console.log('selectedAttiveIds',selectedAttiveIds);

    const selectedSospeseIds = this.form.value.sospese
      .map((checked, i) => checked ? this.sospeseData[i].value: null)
      .filter(v => v !== null);
    console.log('selectedSospeseIds',selectedSospeseIds);

    this.corsaService.getCorse({
      "partenze":selectedPartenzeIds,
      "destinazioni":selectedDestinazioniIds,
      "compagnie":selectedCompagnieIds,
      "attive":selectedAttiveIds,
      "sospese":selectedSospeseIds
      }).subscribe(
      res => this.corse = res['data'],
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );
    
  }

}
