<div *ngIf="Corsa" class="uk-grid uk-padding-small" uk-grid>
    <div class="uk-width-1-3@m"></div>
    <div class="uk-width-1-1 uk-width-1-3@m">
        <ul class="uk-subnav" uk-margin>
            <li><a href="#" routerLink="/corse"><span uk-icon="arrow-left"></span> Torna alle corse</a></li>
        </ul>
        <form #f="ngForm" (submit)="updateCorsa()"  class="uk-margin-small-large uk-margin-small-top uk-grid-small" uk-grid>
                <input type="hidden" name="id" [(ngModel)]="Corsa.id">

            <div class="uk-margin-small uk-width-1-1">
                <label class="uk-form-label" for="orario">Orario</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-small@m" id="orario" type="text" placeholder="12:10" value="{{Corsa.ora}}" [(ngModel)]="Corsa.ora" name="ora">
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="partenza">Partenza</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="partenza" [(ngModel)]="Corsa.part" name="part">
                        <option value="Amalfi" [selected]="Corsa.part=='Amalfi'">Amalfi</option>
                        <option value="Capri" [selected]="Corsa.part=='Capri'">Capri</option>
                        <option value="Casamicciola" [selected]="Corsa.part=='Casamicciola'">Casamicciola</option>
                        <option value="Castellammare" [selected]="Corsa.part=='Castellammare'">Castellammare</option>
                        <option value="Forio" [selected]="Corsa.part=='Forio'">Forio</option>
                        <option value="Ischia" [selected]="Corsa.part=='Ischia'">Ischia</option>
                        <option value="Napoli" [selected]="Corsa.part=='Napoli'">Napoli</option>
                        <option value="Napoli (Mergellina)" [selected]="Corsa.part=='Napoli (Mergellina)'">Napoli (Mergellina)</option>
                        <option value="Napoli (Molo Beverello)" [selected]="Corsa.part=='Napoli (Molo Beverello)'">Napoli (Molo Beverello)</option>
                        <option value="Napoli (Porta di Massa)" [selected]="Corsa.part=='Napoli (Porta di Massa)'">Napoli (Porta di Massa)</option>
                        <option value="Positano" [selected]="Corsa.part=='Positano'">Positano</option>
                        <option value="Pozzuoli" [selected]="Corsa.part=='Pozzuoli'">Pozzuoli</option>
                        <option value="Procida" [selected]="Corsa.part=='Procida'">Procida</option>
                        <option value="Salerno" [selected]="Corsa.part=='Salerno'">Salerno</option>
                        <option value="Sorrento" [selected]="Corsa.part=='Sorrento'">Sorrento</option> 
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="destinazione">Destinazione</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="destinazione" [(ngModel)]="Corsa.dest" name="dest">
                        <option value="Amalfi" [selected]="Corsa.dest=='Amalfi'">Amalfi</option>
                        <option value="Capri" [selected]="Corsa.dest=='Capri'">Capri</option>
                        <option value="Casamicciola" [selected]="Corsa.dest=='Casamicciola'">Casamicciola</option>
                        <option value="Castellammare" [selected]="Corsa.dest=='Castellammare'">Castellammare</option>
                        <option value="Forio" [selected]="Corsa.dest=='Forio'">Forio</option>
                        <option value="Ischia" [selected]="Corsa.dest=='Ischia'">Ischia</option>
                        <option value="Napoli" [selected]="Corsa.dest=='Napoli'">Napoli</option>
                        <option value="Napoli (Mergellina)" [selected]="Corsa.dest=='Napoli (Mergellina)'">Napoli (Mergellina)</option>
                        <option value="Napoli (Molo Beverello)" [selected]="Corsa.dest=='Napoli (Molo Beverello)'">Napoli (Molo Beverello)</option>
                        <option value="Napoli (Porta di Massa)" [selected]="Corsa.dest=='Napoli (Porta di Massa)'">Napoli (Porta di Massa)</option>
                        <option value="Positano" [selected]="Corsa.dest=='Positano'">Positano</option>
                        <option value="Pozzuoli" [selected]="Corsa.dest=='Pozzuoli'">Pozzuoli</option>
                        <option value="Procida" [selected]="Corsa.dest=='Procida'">Procida</option>
                        <option value="Salerno" [selected]="Corsa.dest=='Salerno'">Salerno</option>
                        <option value="Sorrento" [selected]="Corsa.dest=='Sorrento'">Sorrento</option>
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="compagnia">Compagnia</label>
                <div class="uk-form-controls">
                    
                    <select class="uk-select" id="compagnia" [(ngModel)]="Corsa.comp" name="comp">
                        <option *ngFor="let compagnia of compagnieFormArray.controls; let i = index" value="{{compagnieData[i].value}}" [selected]="Corsa.comp==compagnieData[i].value">{{compagnieData[i].name}}</option>
                        
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="mezzo">Mezzo</label>
                <div class="uk-form-controls">
                    <select class="uk-select" id="mezzo" [(ngModel)]="Corsa.mez" name="mez">
                        <option value="Aliscafo" [selected]="Corsa.mez=='Aliscafo'">Aliscafo</option>
                        <option value="Traghetto" [selected]="Corsa.mez=='Traghetto'">Traghetto</option>
                        <option value="Motonave" [selected]="Corsa.mez=='Motonave'">Motonave</option>
                    </select>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-1">
                <label class="uk-form-label" for="descrizione">Descrizione</label>
                <div class="uk-form-controls">
                    <input class="uk-input" id="descrizione" type="text" placeholder="Descrizione" value="{{Corsa.desc}}" [(ngModel)]="Corsa.desc" name="desc">
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <div class="uk-form-label">Attiva</div>
                <div class="uk-form-controls uk-form-controls-text uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-radio" type="radio" value="1" [checked]="Corsa.attiva=='1'" [(ngModel)]="Corsa.attiva" name="attiva"> Si</label><br>
                    <label><input class="uk-radio" type="radio" value="0" [checked]="Corsa.attiva=='0'" [(ngModel)]="Corsa.attiva" name="attiva"> No</label>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <div class="uk-form-label">Sospesa</div>
                <div class="uk-form-controls uk-form-controls-text uk-grid-small uk-child-width-auto uk-grid">
                    <label><input class="uk-radio" type="radio" value="1" [checked]="Corsa.sosp=='1'" [(ngModel)]="Corsa.sosp" name="sosp"> Si</label><br>
                    <label><input class="uk-radio" type="radio" value="0" [checked]="Corsa.sosp=='0'" [(ngModel)]="Corsa.sosp" name="sosp"> No</label>
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="last_update">Ultimo aggiornamento</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-medium@m" id="last_update" type="text" readonly disabled value="{{Corsa.last_update}}">
                </div>
            </div>

            <div class="uk-margin-small uk-width-1-2">
                <label class="uk-form-label" for="creation_date">Data creazione</label>
                <div class="uk-form-controls">
                    <input class="uk-input uk-width-medium@m" id="creation_date" type="text" readonly disabled value="{{Corsa.creation_date}}">
                </div>
            </div>

            <div class="uk-margin-large-bottom uk-width-1-1 uk-text-center">
                <button class="uk-button uk-button-primary" type="submit">Aggiorna</button>
            </div>

        </form>
        <form (submit)="deleteCorsa()">
            <input type="hidden" name="id" [(ngModel)]="Corsa.id">
            <div class="uk-margin uk-text-center">
                <button class="uk-button uk-button-danger" type="submit">Elimina corsa</button>
            </div>
        </form>
    </div>
</div>
<div *ngIf="!Corsa">
    <div class="uk-section uk-section-muted">
        <div class="uk-container">
            <h3>Corsa non trovata</h3>
            <div class="uk-grid-match uk-child-width-1-1" uk-grid>
                <div>
                    <p>L'indirizzo potrebbe essere sbagliato o la corsa potrebbe essere stata cancellata.</p>
                </div>
            </div>
        </div>
    </div>
</div>