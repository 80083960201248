<h1>{{title}}</h1>
<div class="uk-overflow-auto">
<table class="uk-table uk-table-small">
    <tr>
        <th>Nome</th>
        <th>Email</th>
        <th>Azioni</th>
    </tr>
  <tr app-user *ngFor ="let user of users"
   [user-data] ="user"
   (onDeleteUser) ="onDeleteUser($event)"
   (onSelectUser) ="onSelectUser($event)"
   >
  </tr>
</table>
</div>
