import {Injectable} from '@angular/core';
import {Corsa} from '../classes/Corsa';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from "./auth.service";
import { environment } from 'src/environments/environment';

@Injectable()

export class CorsaService {
  users: Corsa[] = [];
  private APIURL = environment.url_api;

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  getAuthHeader(): HttpHeaders {
    const headers = new HttpHeaders(
      {
        Authorization : 'Bearer ' + this.auth.getToken()
      }
    );
    return headers;
  }

  getCorse(data: any) {
    let params = new HttpParams();
    Object.keys(data).forEach(function (key) {
      params = params.append(key, data[key]);
    });

    return  this.http.get(this.APIURL+ 'get_corse.php', {
      headers: this.getAuthHeader(),
      params: params
    });
  }

  getCorsa(id: number) {
    return this.http.get(this.APIURL + 'get_corsa.php?id=' + id,  {
      headers: this.getAuthHeader()
    });
  }

  deleteCorsa(id: number) {
    return this.http.delete(this.APIURL + 'delete_corsa.php?id=' + id,  {
      headers: this.getAuthHeader()
    });

  }

  updateCorsa(corsa: Corsa) {
    return this.http.patch(this.APIURL + 'update_corsa.php?id=' + corsa.id, corsa,  {
      headers: this.getAuthHeader()
    });

  }

  createCorsa(corsa: Corsa) {
    return this.http.post(this.APIURL+ 'create_corsa.php', corsa,  {
      headers: this.getAuthHeader()
    });

  }

  changeAttiva(id: number, attiva: boolean) {
    return this.http.patch(this.APIURL + 'update_corsa_attiva.php?id=' + id, {"attiva":attiva},  {
      headers: this.getAuthHeader()
    });
  }

  changeSospesa(id: number, sospesa: boolean) {
    return this.http.patch(this.APIURL + 'update_corsa_sospesa.php?id=' + id, {"sospesa":sospesa},  {
      headers: this.getAuthHeader()
    });
  }

  getCompagnie() {

    return  this.http.get(this.APIURL+ 'get_compagnie.php', {
      headers: this.getAuthHeader()
    });
  }

}

