import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {UsersComponent} from './users/users.component';
import {UserService} from './services/user.service';
import {AuthService} from "./services/auth.service";
import {CorsaService} from "./services/corsa.service";
import {UserComponent} from './user/user.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserDetailComponent} from './user-detail/user-detail.component';
import {NavComponent} from './nav/nav.component';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ModealBasicComponent} from './modeal-basic/modeal-basic.component';
import { UserDataComponent } from './user-data/user-data.component';
import {HttpClientModule} from '@angular/common/http';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { CorseComponent } from './corse/corse.component';
import { CorsaDetailComponent } from './corsa-detail/corsa-detail.component';
import { CorsaCreateComponent } from './corsa-create/corsa-create.component';

@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    UserComponent,
    UserDetailComponent,
    NavComponent,
    ModealBasicComponent,
    UserDataComponent,
    LoginComponent,
    SignupComponent,
    CorseComponent,
    CorsaDetailComponent,
    CorsaCreateComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [UserService, AuthService, CorsaService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
