import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UsersComponent} from "./users/users.component";
import {UserDetailComponent} from "./user-detail/user-detail.component";
import {UserDataComponent} from "./user-data/user-data.component";
import {RouteGuardService} from "./services/route-guard.service";
import {LoginComponent} from "./login/login.component";
import {SignupComponent} from "./signup/signup.component";
import {CorseComponent} from "./corse/corse.component";
import {CorsaDetailComponent} from "./corsa-detail/corsa-detail.component";
import { CorsaCreateComponent } from './corsa-create/corsa-create.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'corse',
    pathMatch: 'full',
    canActivate: [RouteGuardService]
  },
  {
    path: 'corse',
    component: CorseComponent,
    canActivate: [RouteGuardService]
  },
  {
    path: 'corse/:id/edit',
    component: CorsaDetailComponent,
    canActivate: [RouteGuardService]
  },
  {
    path: 'corse/new',
    component: CorsaCreateComponent,
    canActivate: [RouteGuardService]
  },
  /*{
    path: 'users',
    component: UsersComponent,
    canActivate: [RouteGuardService]
  },
  {
    path: 'users',
    redirectTo: 'users',
    //pathMatch: 'full',
    canActivate: [RouteGuardService]
  },
  {
    path: 'users/new',
    component: UserDetailComponent,
    canActivate: [RouteGuardService]
  },
  {
    path: 'users/:id',
    component: UserDataComponent,
    canActivate: [RouteGuardService]
  },
  {
    path: 'users/:id/edit',
    component: UserDetailComponent,
    canActivate: [RouteGuardService]
  },*/
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  { path: '**', redirectTo: 'corse' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
