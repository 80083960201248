<nav *ngIf="isUserLOggedIn" class="uk-navbar-container" uk-navbar>
  <div class="uk-navbar-left">
      <a class="uk-navbar-toggle" href="#" uk-toggle="target: #offcanvas-nav">
          <span uk-navbar-toggle-icon></span> <span class="uk-margin-small-left">Menu</span>
      </a>
  </div>
  <div class="uk-navbar-center">
    <div style="width: 100px;">
		<a href="/">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1024 500" style="enable-background:new 0 0 1024 500;" xml:space="preserve">
<g>
	<g>
		<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#4DB0D3;" d="M487.694,33.23c-0.074-9.342-6.461-15.927-15.674-16.132
			c-10.032-0.224-20.078-0.194-30.111,0.026c-9.043,0.198-15.811,7.103-15.863,16.084c-0.091,15.811,0.014,31.623,0.036,47.435
			c0.457,0.063,0.916,0.124,1.374,0.187c0.149-0.147,0.297-0.294,0.442-0.442c0.09-0.189,0.183-0.376,0.269-0.565
			c0.126-0.401,5.547-1.338,8.18-1.318c16.066,0.126,32.133-0.122,48.198,0.14c0.35-0.057,0.614-0.211,0.811-0.44
			c0.154,0.497,0.321,0.985,0.349,1.508c0.006,0.35,0.077,0.682,0.22,1.003c0.255,0.23,0.551,0.356,0.888,0.392
			c0.337-0.042,0.615-0.19,0.839-0.443C487.68,64.853,487.819,49.04,487.694,33.23z"/>
		<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#14243B;" d="M667.375,430.784c-3.145,5.571-7.767,7.932-11.647,11.136
			c-21.214,8.775-42.644,9.226-64.279,1.66c-1.784-0.694-5.366-3.071-6.614-4.211c-3.252-2.657-5.667-5.625-8.387-9.235
			c-0.528-0.701-1.146-1.336-1.146-1.336s-0.829,0.946-1.432,1.91c-2.656,4.252-6.139,6.854-10.004,9.455
			c-2.026,1.363-3.483,2.103-6.306,3.578c-19.591,7.057-39.159,6.886-58.706-0.192c-1.722-0.834-2.523-1.499-3.799-2.207
			c-4.579-2.545-8.14-5.809-11.292-10.26c-0.618-0.873-1.169-1.906-1.169-1.906s-1.068,1.619-2.037,2.867
			c-8.69,11.499-21.493,15.103-34.667,16.408c-15.216,1.507-30.357-0.252-44.594-6.659c-4.784-3.312-9.647-6.54-12.19-12.622
			c-3.881,7.709-9.983,11.585-16.843,14.266c-14.677,5.734-29.891,6.585-45.302,3.944c-7.021-1.203-13.99-2.821-20.021-7
			c-3.652-2.531-6.538-5.017-8.211-6.686c-1.225-1.222-2.062-2.348-3.34-5.175c0.154,5.155,0.454,9.146,1.64,13.486
			c3.056,11.19,5.803,21.166,15.17,28.065c11.109,8.182,23.824,10.343,37.22,8.269c16.96-2.625,30.451-10.53,38.199-26.544
			c0.349-0.721,0.426-1.791,1.499-1.644c0.936,0.128,1.045,1.144,1.359,1.896c1.552,3.721,3.616,7.147,6.468,9.994
			c15.549,15.526,33.959,21.005,55.026,14.157c12.616-4.101,23.162-11.491,28.579-24.43c0.319-0.761,0.482-1.677,1.544-1.606
			c1.028,0.069,1.249,0.989,1.44,1.776c0.533,2.198,1.763,4.017,3.034,5.821c13,18.456,39.268,26.311,60.286,17.829
			c11.805-4.764,21.784-11.739,26.834-24.107c0.269-0.657,0.571-1.332,1.379-1.324c1.041,0.01,1.247,0.89,1.5,1.679
			c1.245,3.888,3.501,7.152,6.314,10.046c12.83,13.201,28.233,18.857,46.677,16.547c17.897-2.241,30.478-10.877,36.062-28.461
			C667.475,444.114,668.268,438.095,667.375,430.784z M482.987,432.887c0.051-0.005,0.098-0.016,0.151-0.019
			c0.111,0.149,0.224,0.291,0.337,0.438C483.313,433.165,483.151,433.026,482.987,432.887z"/>
		<g>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#4DB0D3;" d="M489.526,185.802c-9.087,0.313-18.075-0.958-27.185-0.959
				c-9.248-0.001-18.371,1.285-27.597,0.975c-2.445-0.082-3.797,0.874-3.704,3.671c0.165,4.946,0.13,9.902,0.002,14.849
				c-0.058,2.209,0.719,3.005,2.883,2.73c2.587-0.329,5.185-0.635,7.787-0.766c16.205-0.82,32.404-0.959,48.569,0.757
				c2.591,0.275,3.255-0.631,3.077-3.277c-0.304-4.514-0.208-9.075,0.035-13.6C493.553,187.198,492.583,185.696,489.526,185.802z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3A9184;" d="M330.117,210.483c0.898,1.85,2.072,3.746-0.828,4.961
				C329.559,213.79,328.624,211.933,330.117,210.483z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3E9284;" d="M363.186,143.513c0.315,2.546,1.021,5.134-0.824,7.44
				C362.724,148.482,361.549,145.842,363.186,143.513z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3E9284;" d="M367.41,121.995c2.662,2.401,1.511,4.207-0.908,5.815
				C367.665,126.005,367.198,123.947,367.41,121.995z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3A9184;" d="M332.66,196.425c0.97,2.12,1.88,4.23-0.84,5.817
				C332.1,200.303,332.38,198.364,332.66,196.425z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3E9284;" d="M369.804,112.921c-0.494,1.62,1.998,3.732-0.818,4.97
				C369.236,116.23,368.318,114.377,369.804,112.921z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3C9284;" d="M357.422,155.945c-0.882,2.925-3.321,1.082-4.98,1.627
				C353.798,156.1,355.982,157.161,357.422,155.945z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3C9284;" d="M334.34,186.509c1.12,1.946,1.251,3.876-0.09,5.784
				C334.28,190.365,334.31,188.437,334.34,186.509z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3C9284;" d="M335.943,179.05c1.24,0.848,2.522,1.697-0.024,2.509
				C335.927,180.722,335.935,179.887,335.943,179.05z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3C9284;" d="M339.225,166.652c2.562,0.845,1.285,1.667-0.014,2.491
				C339.215,168.312,339.22,167.482,339.225,166.652z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3C9284;" d="M351.612,158.394c-0.488,0.733-0.604,2.17-1.544,2.018
				c-0.914-0.149,0.109-1.298-0.133-1.985C350.494,158.416,351.053,158.406,351.612,158.394z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#4DB0D3;" d="M409.848,189.121c-9.551,1.722-18.942,4.061-28.263,6.787
				c-4.641,1.357-6.524,3.964-6.846,8.656c-0.297,4.325-1.371,8.6-2.164,12.885c-0.148,0.799-0.703,1.637,0.081,2.27
				c0.831,0.669,1.455-0.27,2.182-0.524c11.222-3.912,22.566-7.346,34.397-8.936c6.708-0.902,6.7-0.962,6.708-7.903
				c0.002-1.099,0-2.198,0.003-3.297c0.002-1.649,0.003-3.297,0.002-4.945C415.947,188.326,415.647,188.075,409.848,189.121z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3E9284;" d="M382.991,136.078c-1.481-1.964-0.044-3.422,0.86-4.973
				C383.585,132.767,384.551,134.641,382.991,136.078z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3E9284;" d="M385.493,125.331c-1.568-1.566-0.493-2.477,0.826-3.327
				C386.466,123.218,386.071,124.297,385.493,125.331z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3A9184;" d="M348.312,208.832c-1.44-2.283-1.483-4.125,1.574-4.988
				C348.744,205.312,349.77,207.463,348.312,208.832z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#3A9184;" d="M350.753,198.913c-2.124-1.885-1.056-3.099,0.869-4.132
				C351.333,196.158,351.043,197.535,350.753,198.913z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#4DB0D3;" d="M488.604,124.504c-8.866-1.168-17.748-1.74-25.274-1.664
				c-16.667-0.06-31.739,1.536-46.603,5.032c-1.796,0.422-2.876,1.289-3.186,3.235c-0.793,4.987-1.719,9.955-2.608,14.927
				c-0.474,2.656,0.209,3.565,3.063,2.588c3.226-1.105,6.561-2.044,9.925-2.566c16.028-2.484,32.123-3.69,48.381-2.844
				c4.933,0.257,9.816,1.035,14.756,1.055c1.769,0.007,3.409-0.037,3.327-2.48c-0.157-4.679,0.198-9.323,0.986-13.936
				C491.74,125.681,491.037,124.825,488.604,124.504z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#4DB0D3;" d="M503.729,80.047c-3.607-0.683-7.199-1.543-10.856-1.862
				c-0.271-0.023-28.675-1.781-60.034,0.178c-0.66,0.041-6.087,0.892-8.396,1.166c-13.107,1.554-25.824,4.786-38.43,8.589
				c-7.626,2.3-13.931,5.788-15.672,14.477c-2.876,14.354-6.04,28.651-8.766,43.032c-1.045,5.515-2.715,9.332-9.094,10.623
				c-10.241,2.072-15.579,8.897-17.219,19.4c-3.569,22.863-9.471,45.273-13.954,67.965c-1.284,6.499-2.753,12.962-4.283,20.13
				c7.342-3.656,24.066-10.95,24.573-12.889c0.826-3.158,2.842-14.075,3.239-16.223c3.418-18.514,7.594-36.868,11.557-55.267
				c0.456-2.119,1.532-2.919,3.323-3.618c2.624-1.024,7.829-3.2,7.829-3.2c6.484-2.621,10.586-7.001,12.074-13.434
				c3.577-15.465,6.734-31.029,9.946-46.577c0.556-2.692,1.568-4.404,4.454-5.118c6.343-1.572,12.611-3.444,18.91-5.195l0.001,0
				c0.945-0.172,1.321-0.131,2.244-0.328c15.739-3.365,32.364-4.204,48.375-4.336c10.376-0.086,20.782,0.841,31.102,2.2
				c2.279,0.3,3.461-0.262,4.347-2.52c1.902-4.845,3.719-9.758,6.442-14.233C506.772,80.823,505.307,80.345,503.729,80.047z
				 M320.561,258.851c-0.046,0.547-0.257,0.178-0.126-0.351c0.061-0.244,0.109-0.491,0.164-0.736
				c0.057,0.333,0.143,0.665,0.251,0.996C320.692,258.807,320.563,258.834,320.561,258.851z M337.638,252.553
				c-0.037-0.058-0.069-0.117-0.121-0.174c-0.117-0.086-0.245-0.147-0.371-0.212c0.468-0.441,1.02-0.797,1.56-1.163
				C338.262,251.464,337.844,251.941,337.638,252.553z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#E44320;" d="M615.19,28.681c-64.234,0-116.305,52.071-116.305,116.304
				c0,64.233,52.071,116.305,116.305,116.305c64.232,0,116.303-52.072,116.303-116.305C731.493,80.752,679.422,28.681,615.19,28.681
				z M615.19,231.796c-47.944,0-86.811-38.867-86.811-86.81c0-47.944,38.866-86.811,86.811-86.811
				c47.943,0,86.81,38.867,86.81,86.811C702,192.929,663.133,231.796,615.19,231.796z"/>
			<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#14243B;" d="M629.48,140.427c-1.332-17.475-3.042-34.924-4.803-52.363
				c-0.292-2.898,0.226-6.124-2.024-8.64c-2.568-2.873-5.862-2.876-9.135-2.044c-3.878,0.985-5.47,4.098-5.605,7.782
				c-0.283,7.68-1.174,15.295-1.918,22.933c-1.276,13.084-2.341,26.191-3.411,39.294c-0.166,2.036-0.94,3.594-2.217,5.143
				c-4.871,5.907-9.629,11.908-14.43,17.873c-3.078,3.663-6.255,7.247-9.206,11.01c-2.842,3.625-2.618,5.747,0.509,8.958
				c4.634,4.76,8.112,5.721,11.737,3.028c12.112-8.994,24.191-18.033,36.258-27.088c2.199-1.65,4.344-3.409,4.723-6.428l0,0
				C631.967,153.354,629.971,146.875,629.48,140.427z"/>
		</g>
		<path style="fill-rule:evenodd;clip-rule:evenodd;fill:#4DB0D3;" d="M651.469,304.327
			c-22.799-11.037-114.286-58.883-141.384-67.178c-10.128-3.101-14.124-5.029-28.249-5.718c0,0-9.578,0.551-21.566,4.065
			c-11.989,3.514-105.211,43.201-121.954,53.122c-13.728,8.135-32.452,17.983-38.447,23.771c-5.994,5.788-2.113,9.578,3.514,16.329
			c8.268,9.922,16.95,30.592,25.631,64.904c9.171,36.249,41.547,27.491,49.195,20.67c7.648-6.821,8.888-10.542,10.542-10.335
			s11.162,20.05,48.368,20.05s42.787-20.257,44.854-20.257s5.994,19.017,51.055,19.017c32.659,0,39.819-19.083,42.233-19.083
			s0.897,14.467,27.975,14.467c33.762,0,33.831-44.441,40.239-57.463c6.408-13.022,17.081-32.182,20.188-35.897
			C665.912,322.103,672.251,314.386,651.469,304.327z M435.646,340.293c-9.818,0-17.776-10.735-17.776-23.977
			c0-13.243,7.959-23.977,17.776-23.977s17.776,10.735,17.776,23.977C453.422,329.558,445.463,340.293,435.646,340.293z
			 M527.421,340.293c-9.818,0-17.776-10.735-17.776-23.977c0-13.243,7.959-23.977,17.776-23.977s17.776,10.735,17.776,23.977
			C545.198,329.558,537.239,340.293,527.421,340.293z"/>
	</g>
	<path style="fill:#E44320;" d="M716.959,261.251L716.959,261.251c-8.208,0-14.923-6.038-14.923-13.419V144.184
		c0-7.38,6.715-13.419,14.923-13.419h0c8.208,0,14.923,6.038,14.923,13.419v103.648
		C731.882,255.212,725.167,261.251,716.959,261.251z"/>
</g>
</svg>
</a>
</div>
  </div>
  <div class="uk-navbar-right">
      <ul class="uk-navbar-nav"> 
		<li>
			<a href="#"><span uk-icon="icon: user"></span></a>
			<div class="uk-navbar-dropdown uk-navbar-dropdown-bottom-right">
				<ul class="uk-nav uk-navbar-dropdown-nav">
					<!--li><a href="#">{{username}}</a></li-->
					  <li>
						<a href="#" (click)="logout($event)"><span class="uk-margin-small-right uk-icon" uk-icon="icon: sign-out"></span> Esci</a>
					  </li>
				</ul>
			</div>
		</li>
      </ul>
  </div>
</nav>

<div *ngIf="isUserLOggedIn" id="offcanvas-nav" uk-offcanvas="overlay: true">
  <div class="uk-offcanvas-bar">
    <button class="uk-offcanvas-close" type="button" uk-close></button>
    <ul class="uk-nav uk-nav-default">
      <li>
        <a href="#" routerLink="" uk-toggle="target: #offcanvas-nav"><span class="uk-margin-small-right uk-icon" uk-icon="icon: home"></span> Home</a>
      </li>
      <li>
        <a href="#" routerLink="/corse" uk-toggle="target: #offcanvas-nav"><span class="uk-margin-small-right uk-icon" uk-icon="icon: table"></span> Corse</a>
      </li>
	  <li>
        <a href="#" routerLink="/corse/new" uk-toggle="target: #offcanvas-nav"><span class="uk-margin-small-right uk-icon" uk-icon="icon: plus-circle"></span> Aggiungi corsa</a>
      </li>
  </ul>
  </div>
</div>
