<form #f="ngForm">
  <input type="hidden" name="id" [(ngModel)]="user.id">
  <div class="form-row">
    <div class="form-group col-sm-6">
      <label for="nome">Nome</label>
      <input required minlength="3" #nome="ngModel" placeholder="Nome"
             [(ngModel)]="user.nome" class="form-control" name="nome" id="nome">
      <div *ngIf="nome.dirty && nome.touched && nome.invalid">

        <div class="alert-danger" *ngIf="nome.errors.required">
          The field first name is required
        </div>
        <div class="alert-danger" *ngIf="nome.errors.minlength">
          The min length of field is first name {{nome.errors.minlength.requiredLength }} chars
        </div>
        <div class="alert-danger" *ngIf="nome.errors.pattern">
          The field first pattern is {{nome.errors.pattern.requiredPattern }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-group  col-sm-6">
    <label for="password">Password</label>
    <input placeholder="Password" [(ngModel)]="user.password" class="form-control" name="password" id="password">

  </div>

  <div class="form-row">
    <div class="form-group   col-sm-6">
      <label for="email">Email</label>
      <input [(ngModel)]="user.email" class="form-control" type="email" name="email" id="email">
    </div>
  </div>

  <div class="form-group form-footer text-center">
    <button (click)="backToUsers()" class="btn btn-primary">BACK</button>
    <button class="btn btn-secondary" (click)="resetForm(f.form)">RESET</button>
    <button [disabled]="f.invalid" class="btn btn-success" (click)="saveUser()"> SAVE</button>
  </div>
</form>
