<form [formGroup]="form" id="form-filtra-corse">
      
    <ul class="uk-subnav">
        <li class="uk-margin-top uk-margin-left uk-display-inline">
            <a class="uk-button uk-button-default" href="#">Partenze <span uk-icon="icon: triangle-down"></span></a>
            <div uk-dropdown="mode: click;">
                <ul class="uk-nav uk-dropdown-nav">
                    <li formArrayName="partenze" *ngFor="let partenza of partenzeFormArray.controls; let i = index"><label><input class="uk-checkbox" type="checkbox" [formControlName]="i" (change)="filtraCorse()"> {{partenzeData[i].name}}</label></li>
                </ul>
            </div>
        </li>
    <li class="uk-margin-top uk-display-inline">
        <a class="uk-button uk-button-default" href="#">Destinazioni <span uk-icon="icon: triangle-down"></span></a>
        <div uk-dropdown="mode: click;">
            <ul class="uk-nav uk-dropdown-nav">
                <li formArrayName="destinazioni" *ngFor="let destinazione of destinazioniFormArray.controls; let i = index"><label><input class="uk-checkbox" type="checkbox" [formControlName]="i" (change)="filtraCorse()"> {{destinazioniData[i].name}}</label></li>
            </ul>
        </div>
    </li>
    <li class="uk-margin-top uk-display-inline" *ngIf="compagnieFormArray.controls.length>1">
        <a class="uk-button uk-button-default" href="#">Compagnie <span uk-icon="icon: triangle-down"></span></a>
        <div uk-dropdown="mode: click;">
            <ul class="uk-nav uk-dropdown-nav">
                <li formArrayName="compagnie" *ngFor="let compagnia of compagnieFormArray.controls; let i = index"><label><input class="uk-checkbox" type="checkbox" [formControlName]="i" (change)="filtraCorse()"> {{compagnieData[i].name}}</label></li>
            </ul>
        </div>
    </li>
    <li class="uk-margin-top uk-display-inline">
        <a class="uk-button uk-button-default" href="#">Attive <span uk-icon="icon: triangle-down"></span></a>
        <div uk-dropdown="mode: click;">
            <ul class="uk-nav uk-dropdown-nav">
                <li formArrayName="attive" *ngFor="let attiva of attiveFormArray.controls; let i = index"><label><input class="uk-checkbox" type="checkbox" [formControlName]="i" (change)="filtraCorse()"> {{attiveData[i].name}}</label></li>
            </ul>
        </div>
    </li>
    <li class="uk-margin-top uk-display-inline">
        <a class="uk-button uk-button-default" href="#">Sospese <span uk-icon="icon: triangle-down"></span></a>
        <div uk-dropdown="mode: click;">
            <ul class="uk-nav uk-dropdown-nav">
                <li formArrayName="sospese" *ngFor="let sospesa of sospeseFormArray.controls; let i = index"><label><input class="uk-checkbox" type="checkbox" [formControlName]="i" (change)="filtraCorse()"> {{sospeseData[i].name}}</label></li>
            </ul>
        </div>
    </li>
</ul>

</form>
<table class="uk-table uk-table-small uk-table-responsive uk-table-middle uk-table-divider uk-table-striped">
    <thead>
        <tr>
            <!--th class="uk-table-shrink"></th-->
            <th class="uk-table-shrink uk-text-center">Compagnia</th>
            <th>Orario</th>
            <th>Partenza</th>
            <th>Destinazione</th>
            <th>Info</th>
            <th>Aggiornamento</th>
            <th>Attiva</th>
            <th>Sospesa</th>
            <th>Azione</th>
        </tr>
    </thead>
    <tbody>
        <tr app-corse *ngFor ="let corsa of corse">
            <!--td><input class="uk-checkbox" type="checkbox"></td-->
            <td class="uk-text-center"><img class="uk-preserve-width uk-border-circle" src="assets/img/{{corsa.comp|lowercase}}_circle.png" width="40" alt="logo {{corsa.comp}}"></td>   
            <td><span class="uk-hidden@s">Ora:</span> {{corsa.ora}}</td>
            <td class="col-1-2">{{corsa.part}}</td>
            <td class="col-1-2 col-destinazione"><span class="icona-destinazione uk-hidden@m" uk-icon="arrow-right"></span>{{corsa.dest}}</td>
            <td class="col-descrizione">{{corsa.desc}}</td>
            <td><span class="uk-hidden@s">Aggiornata il:</span> {{corsa.last_update}}</td>
            <td class="col-attiva uk-text-center@m">
                <span class="uk-hidden@s">Attiva: </span>
                <label class="uk-switch">
                    <input type="checkbox" [checked]="corsa.attiva==true" [(ngModel)]="corsa.attiva" name="attiva" (change)="changeAttiva(corsa.id, corsa.attiva)">
                    <div class="uk-switch-slider uk-switch-on-off"></div>
                </label>
            </td>
            <td class="col-sospesa uk-text-center@m">
                <span class="uk-hidden@s">Sospesa: </span>
                <label class="uk-switch">
                    <input type="checkbox" [checked]="corsa.sosp==true" [(ngModel)]="corsa.sosp" name="sosp" (change)="changeSospesa(corsa.id, corsa.sosp)">
                    <div class="uk-switch-slider uk-switch-on-off"></div>
                </label>
            </td>
            <td class="col-modifica uk-text-center"><button class="uk-button uk-button-primary" type="button" routerLink="/corse/{{corsa.id}}/edit">Modifica</button></td>
        </tr>
        <tr *ngIf="corse.length==0">
            <td class="uk-text-center" colspan="10">Nessuna corsa</td>
        </tr>
    </tbody>
</table>