<h2>{{ title }}</h2>
<table class="table">
  <tr>
    <th>Nome</th>
    <td>{{User.nome}}</td>
  </tr>
  <tr>
    <th>Email</th>
    <td>{{User.email}}</td>
  </tr>
  <tr>
    <th>Livello</th>
    <td>{{User.livello}}</td>
  </tr>
  <tfoot>
  <tr> <td colspan="2"><button class="btn btn-primary" (click)="backToUsers()">BACK</button></td> </tr>
  </tfoot>
</table>
