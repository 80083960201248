import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from '../services/user.service';
import {AuthService} from "../services/auth.service";
import {User} from '../classes/User';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {
  title = 'Users';
  users: User[] = [];
  @Output('updateUser') updateUser = new EventEmitter<User>();

  constructor(private userService: UserService, private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.userService.getUsers().subscribe(
      res => this.users = res['data'],
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          console.log('error.error.text')
          alert(error.error.text)
        }
      }
      );
  }

  onDeleteUser(user: User) {
    const deleteUser = confirm('Do you really want to delete user '+ user.nome );
    if(deleteUser) {
      this.userService.deleteUser(user).subscribe(
        response => {
        const idx = this.users.indexOf(user);
        this.users.splice(idx, 1);
          alert(response['message']);

        }
      );
    }
  }

  onSelectUser(user: User) {
    const userCopy = Object.assign({}, user);
    this.updateUser.emit(userCopy);
  }
}
