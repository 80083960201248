import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Corsa } from '../classes/Corsa';
import { CorsaService } from '../services/corsa.service';
import { AuthService } from "../services/auth.service";
declare var UIkit: any;

@Component({
  selector: 'app-corsa-create',
  templateUrl: './corsa-create.component.html',
  styleUrls: ['./corsa-create.component.css']
})
export class CorsaCreateComponent implements OnInit {
  public Corsa: Corsa;
  public title = '';


  form: FormGroup;
  partenzeData = [
    {name: 'Amalfi', value: 'Amalfi'},
    {name: 'Capri', value: 'Capri'},
    {name: 'Casamicciola', value: 'Casamicciola'},
    {name: 'Castellammare', value: 'Castellammare'},
    {name: 'Forio', value: 'Forio'},
    {name: 'Ischia', value: 'Ischia'},
    {name: 'Napoli', value: 'Napoli'},
    {name: 'Napoli (Mergellina)', value: 'Napoli (Mergellina)'},
    {name: 'Napoli (Molo Beverello)', value: 'Napoli (Molo Beverello)'},
    {name: 'Napoli (Porta di Massa)', value: 'Napoli (Porta di Massa)'},
    {name: 'Positano', value: 'Positano'},
    {name: 'Pozzuoli', value: 'Pozzuoli'},
    {name: 'Procida', value: 'Procida'},
    {name: 'Salerno', value: 'Salerno'},
    {name: 'Sorrento', value: 'Sorrento'}
  ];
  destinazioniData = [
    {name: 'Amalfi', value: 'Amalfi'},
    {name: 'Capri', value: 'Capri'},
    {name: 'Casamicciola', value: 'Casamicciola'},
    {name: 'Castellammare', value: 'Castellammare'},
    {name: 'Forio', value: 'Forio'},
    {name: 'Ischia', value: 'Ischia'},
    {name: 'Napoli', value: 'Napoli'},
    {name: 'Napoli (Mergellina)', value: 'Napoli (Mergellina)'},
    {name: 'Napoli (Molo Beverello)', value: 'Napoli (Molo Beverello)'},
    {name: 'Napoli (Porta di Massa)', value: 'Napoli (Porta di Massa)'},
    {name: 'Positano', value: 'Positano'},
    {name: 'Pozzuoli', value: 'Pozzuoli'},
    {name: 'Procida', value: 'Procida'},
    {name: 'Salerno', value: 'Salerno'},
    {name: 'Sorrento', value: 'Sorrento'}
  ];
  compagnieData = [];
  attiveData = [
    {name: 'Attive', value: '1'},
    {name: 'Non attive', value: '0'},
  ];
  sospeseData = [
    {name: 'Sospese', value: '1'},
    {name: 'Non sospese', value: '0'},
  ];

  get partenzeFormArray() {
    return this.form.controls.partenze as FormArray;
  }
  get destinazioniFormArray() {
    return this.form.controls.destinazioni as FormArray;
  }
  get compagnieFormArray() {
    return this.form.controls.compagnie as FormArray;
  }
  get attiveFormArray() {
    return this.form.controls.attive as FormArray;
  }
  get sospeseFormArray() {
    return this.form.controls.sospese as FormArray;
  }

  constructor(private route:ActivatedRoute, private corsaService: CorsaService, private auth: AuthService, private router: Router, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      partenze: new FormArray([]),
      destinazioni: new FormArray([]),
      compagnie: new FormArray([]),
      attive: new FormArray([]),
      sospese: new FormArray([])
    });

   }

   private addCheckboxes() {
    this.partenzeData.forEach(() => this.partenzeFormArray.push(new FormControl(false)));
    this.destinazioniData.forEach(() => this.destinazioniFormArray.push(new FormControl(false)));
    this.compagnieData.forEach(() => this.compagnieFormArray.push(new FormControl(false)));
    this.attiveData.forEach(() => this.attiveFormArray.push(new FormControl(false)));
    this.sospeseData.forEach(() => this.sospeseFormArray.push(new FormControl(false)));
  }


  ngOnInit(): void {
    this.Corsa = new Corsa();

    this.corsaService.getCompagnie().subscribe(
      res => {
        this.compagnieData = res['data'];
        this.addCheckboxes();
      },
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );
  }

  createCorsa() {
    console.log('createCorsa', this.Corsa)
    this.corsaService.createCorsa(this.Corsa).subscribe(
      response => {

        if (response['success']) {
          UIkit.notification({
            message: 'Corsa aggiunta',
            status: 'primary',
            pos: 'top-right',
            timeout: 5000
          });
          //this.router.navigate(['corse']);
          console.log(response['data'])
          //this.Corsa = response['data'];
        } else {
          alert(response['message']);
        }
      },
      error => { 
        console.log('oops', error)
        if(error.status==401){
          console.log('401')
          this.auth.logout();
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 300)
        }
        else {
          alert(error.error.text)
        }
      }
    );
  }

}
