<td>{{user.nome}}</td>
<td>{{user.email}}</td>
<td class="buttons">
  
      <button class="btn btn-primary" (click)="showUserDetail()">
        <i class="fas fa-info"></i>
      </button>
    
      <button class="btn btn-primary btn-sm" (click)="updateUser()">
        <i class="fas fa-edit"></i>
      </button>
   
      <button class="btn btn-danger btn-sm" (click)="deleteUser()">
        <i class="fas fa-trash"></i>
      </button>
   
</td>
